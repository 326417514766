/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import chunk from "lodash/chunk"
import some from "lodash/some"
import get from "lodash/get"
import { Flex } from "@theme-ui/components"
import { TabPanel, TabPanels, Tabs } from "@reach/tabs"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { useMedia } from "react-use"
import {
  getValueRange,
  mediaQueryList,
  findAndReplaceVariableValue,
  getValueList,
  hasVariableValue,
} from "../helpers"

import { useIsClient } from "../hooks/use-is-client"
import { useSticky } from "../hooks/use-sticky"

import Modal from "../components/modal"
import Carousel from "../components/carousel"
import Column from "../components/column"
import Container from "../components/container"
import CTA from "../components/cta"
import DesignPartnerDetails from "../components/design-partner-details"
import PageLink from "../components/page-link"
import ProjectCard from "../components/project-card"
import Row from "../components/row"
import SEO from "../components/seo"
import SpecificationsDetails from "../components/specifications-details"
import SpecificationsOverview from "../components/specifications-overview"
import { Tab, TabList } from "../components/custom-tabs"
import Text from "../components/text"
import TabbedCards from "../components/tabbed-cards"
import GatewayForm from "../components/gateway-form"
import ImageModal from "../components/image-modal"
import "./hover-icon.css"
import queryString from "query-string"
import { paramsCheck } from "../functions/functions"
import RichText from "../components/rich-text"


const sharedStyleList = {
  list: {
    container: {
      alignItems: "center",
      display: "flex",
      listStyle: "none",
      m: 0,
      ml: [0, 0, "auto"],
    },
    item: {
      m: 0,
      mr: [2, 4, 5],
      ":last-child": { mr: 0 },
    },
  },
}

const costQuoteNest = {
  description: {
    json: {
      nodeType: "document",
      data: {},
      content: [
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "Determined by project size and specifications. Please read our ",
              marks: [
                {
                  type: "italic",
                },
              ],
              data: {},
            },
            {
              nodeType: "hyperlink",
              content: [
                {
                  nodeType: "text",
                  value: "pricing page ",
                  marks: [
                    {
                      type: "italic",
                    },
                  ],
                  data: {},
                },
              ],
              data: {
                uri: "/",
              },
            },
            {
              nodeType: "text",
              value: "and ",
              marks: [
                {
                  type: "italic",
                },
              ],
              data: {},
            },
            {
              nodeType: "hyperlink",
              content: [
                {
                  nodeType: "text",
                  value: "contact us ",
                  marks: [
                    {
                      type: "italic",
                    },
                  ],
                  data: {},
                },
              ],
              data: {
                uri: "/contact/",
              },
            },
            
            {
              nodeType: "text",
              value: " for an estimate.",
              marks: [
                {
                  type: "italic",
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "",
              marks: [
                {
                  type: "italic",
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
  },
}
const costQuoteOthers = {
  description: {
    json: {
      nodeType: "document",
      data: {},
      content: [
        // {
        //   nodeType: "paragraph",
        //   content: [
        //     {
        //       nodeType: "text",
        //       value: "*See ",
        //       marks: [
        //         {
        //           type: "italic",
        //         },
        //       ],
        //       data: {},
        //     },
        //     {
        //       nodeType: "hyperlink",
        //       content: [
        //         {
        //           nodeType: "text",
        //           value: "pricing page",
        //           marks: [
        //             {
        //               type: "italic",
        //             },
        //           ],
        //           data: {},
        //         },
        //       ],
        //       data: {
        //         uri: "/",
        //       },
        //     },
        //     {
        //       nodeType: "text",
        //       value: " for estimate assumptions and exclusions",
        //       marks: [
        //         {
        //           type: "italic",
        //         },
        //       ],
        //       data: {},
        //     },
        //   ],
        //   data: {},
        // },
        // {
        //   nodeType: "paragraph",
        //   content: [
        //     {
        //       nodeType: "text",
        //       value: "",
        //       marks: [
        //         {
        //           type: "italic",
        //         },
        //       ],
        //       data: {},
        //     },
        //   ],
        //   data: {},
        // },
      ],
    },
  },
}

const renderCarousel = ({ standardHome }) => {
  const imageList = []

  standardHome.forEach(homeData => {
    if (homeData.images && homeData.images.length) {
      homeData.images.forEach(imageData => {
        imageList.push(imageData)
      })
    }
  })

  return imageList.length ? (
    <Container fullWidth={[true, true, true]}>
      <Row noGutters={[true, true, true]}>
        <Column noGutters={[true, true, true]}>
          <Carousel type="tertiary">
            {imageList.map((contentData, index) => (
              <div
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <img
                  alt={contentData.title}
                  src={contentData.file.url}
                  key={contentData.id + index}
                />
              </div>
            ))}
          </Carousel>
        </Column>
      </Row>
    </Container>
  ) : null
}
const FinishedProjectsSection = ({ modelProjectList, ...restProps }) => {
  const isClient = useIsClient()
  const mediaQueryMatch = useMedia(mediaQueryList({ mediaPrefix: false })[1])
  const renderProjectCard = projectData => {
    return (
      <ProjectCard
        background={projectData.projectImages[0].file.url}
        link={{
          to: `/projects/${projectData.slug}`,
          type: "internal",
        }}
        text={[projectData.projectTitle.projectTitle].concat(
          projectData.projectLocation.projectLocation
        )}
      />
    )
  }
  const renderProjectList = () => {
    let projectListOutput = null

    if (!isClient || (isClient && mediaQueryMatch)) {
      projectListOutput = modelProjectList.slice(0, 3).map(projectDataNode => {
        const { node: projectData } = projectDataNode

        return (
          <Column size={[12, 12, 4]} key={projectData.id}>
            {renderProjectCard(projectData)}
          </Column>
        )
      })
    } else {
      projectListOutput = (
        <Column>
          <Carousel type="tertiary">
            {modelProjectList.map(projectDataNode => {
              const { node: projectData } = projectDataNode

              return (
                <div key={projectData.id}>{renderProjectCard(projectData)}</div>
              )
            })}
          </Carousel>
        </Column>
      )
    }

    return projectListOutput
  }

  return (
    <Container
      customStyle={{ py: theme => theme.spacing.vertical.lg }}
      {...restProps}
    >
      <Row>
        <Column size={[12, 12, null]}>
          <Text type="h2">
            Finished
            <br />
            Projects
          </Text>
        </Column>
        <Column
          size={[12, 12, null]}
          customStyle={{
            alignItems: ["flex-start", "flex-start", "flex-end"],
            justifyContent: "flex-end",
            mt: theme => [
              theme.spacing.vertical.sm,
              theme.spacing.vertical.sm,
              0,
            ],
          }}
        >
          <div>
            <CTA link="/projects" linkType="internal">
              Explore Project Gallery
            </CTA>
          </div>
        </Column>
      </Row>
      <Row customStyle={{ pt: theme => theme.spacing.vertical.lg }}>
        {renderProjectList()}
      </Row>
    </Container>
  )
}

const ModelStickyHeader = ({ title, configuratorUrl }) => {
  const isClient = useIsClient()
  const sticky = useSticky()
  const headerHeight = 72
  const listItemStyle = Object.assign({}, sharedStyleList.list.item, { mr: 4 })
  const linkStyle = {
    color: "text",
    p: {
      pt: "20px",
    },
    ...(sticky.action === "pin" && { color: "white" }),
    ":hover": { color: "primary" },
  }

  let newURL
  let url = typeof window !== "undefined" ? window.location.href : ""
  if (url) {
    if (localStorage.getItem("parameter")) {
      let urlformdata = JSON.parse(localStorage.getItem("formObj"))
      if (configuratorUrl) {
        newURL = queryString.stringifyUrl(
          { url: configuratorUrl, query: urlformdata },
          { encode: true, sort: false }
        )
      }
    }
  }

  return (
    <div
      sx={{
        height: headerHeight,
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Flex
        sx={{
          alignItems: "center",
          backgroundColor: "white",
          height: headerHeight,
          width: "100%",
          transition: theme => `background-color ${theme.transitions}`,
          ...(sticky.action === "pin" && {
            bg: "accent",
            position: "fixed",
            top: 0,
            zIndex: 2,
          }),
        }}
      >
        <Container>
          <Row>
            <Column
              customStyle={{ alignItems: "center", flexDirection: "row" }}
            >
              <Text
                customStyle={{
                  alignItems: "center",
                  color: "white",
                  display: ["none", "none", "none", "block"],
                  opacity: 0,
                  pr: [0, 0, theme => theme.spacing.horizontal],
                  transition: theme => `opacity ${theme.transitions}`,
                  ...(sticky.action === "pin" && { opacity: 1 }),
                }}
                type="h3"
              >
                {title}
              </Text>
              <ul
                sx={Object.assign({}, sharedStyleList.list.container, {
                  ml: [0, 0, 0, "auto"],
                  transform: ["none", "none", "none", "translateX(150px)"],
                  transition: theme => `transform ${theme.transitions}`,
                  width: ["100%", "100%", "100%", "auto"],
                  ...(sticky.action === "pin" && {
                    transform: "translateX(0)",
                  }),
                })}
                // style={{marginLeft:"35%"}}
              >
                <li sx={listItemStyle}>
                  <PageLink
                    customStyle={linkStyle}
                    link="#model-overview"
                    target="_self"
                    text="Overview"
                  />
                </li>
                <li sx={listItemStyle}>
                  <PageLink
                    customStyle={linkStyle}
                    link="#technical-details"
                    target="_self"
                    text="Technical"
                  />
                </li>
                {configuratorUrl ? (
                  <li
                    sx={{
                      ...listItemStyle,
                      display: ["none", "none", "none", "block"],
                    }}
                  >
                    {/* <CTA
                      link={newURL ? newURL : configuratorUrl}
                      type="secondary"
                    >
                      Customize & Explore in 3D
                    </CTA> */}
                  </li>
                ) : null}

                <li sx={listItemStyle}>
                  <PageLink
                    customStyle={linkStyle}
                    link={
                      isClient
                        ? `/contact?ref=${window.location.href}`
                        : "/contact"
                    }
                    target="_self"
                    text="Contact Us"
                  />
                </li>
                {/* <li sx={{ ...listItemStyle, ml: ["auto", "auto", "auto", 0] }}>
                  <PageLink
                    customStyle={{
                      color: "white",
                      display: ["none", "none", "none", "block"],
                      fontSize: 3,
                      fontWeight: "heading",
                      opacity: 0,
                      transition: theme => `opacity ${theme.transitions}`,
                      whiteSpace: "nowrap",
                      ...(sticky.action === "pin" && { opacity: 1 }),
                    }}
                    link={
                      isClient
                        ? `/contact?ref=${window.location.href}`
                        : "/contact"
                    }
                    text="Learn More"
                  />
                  <CTA
                    customStyle={{
                      display: ["block", "block", "block", "none"],
                      pr: [50, null],
                      whiteSpace: "nowrap",
                    }}
                    link={
                      isClient
                        ? `/contact?ref=${window.location.href}`
                        : "/contact"
                    }
                    linkType="internal"
                    type="secondary"
                  >
                    Get a Quote
                  </CTA>
                </li> */}
              </ul>
            </Column>
          </Row>
        </Container>
      </Flex>
    </div>
  )
}

const TechnicalDetailsContentRow = ({
  children,
  customStyle,
  richText,
  title,
  type,
}) => {
  const renderRichText = () => {
    const richTextContent = richText.json.content.filter(richTextJSONContent =>
      some(
        richTextJSONContent.content,
        richTextContent => richTextContent.value
      )
    )

    return chunk(richTextContent, Math.ceil(richTextContent.length / 2)).map(
      (richTextGroup, index) => (
        <Column key={index} size={[12, 12, 3]}>
          {documentToReactComponents(
            {
              content: richTextGroup,
              data: {},
              nodeType: "document",
            },
            {
              renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) => {
                  return node.content[0].value ? (
                    <Text
                      customStyle={{
                        marginBottom: "10px",
                        lineHeight: "1.15em",
                        // fontWeight: "bold"
                      }}
                    >
                      {children}
                    </Text>
                  ) : null
                },
              },
            }
          )}
        </Column>
      )
    )
  }

  return (
    <div
      sx={{
        backgroundColor: theme =>
          type && type === "secondary"
            ? "white"
            : theme.colors.backgrounds.primary,
        py: theme => theme.spacing.vertical.sm,
        pb: theme => theme.spacing.vertical.md,
        ...customStyle,
      }}
    >
      <Container>
        <Row>
          <Column
            customStyle={{
              mb: theme => [
                theme.spacing.vertical.sm,
                theme.spacing.vertical.sm,
                0,
              ],
            }}
            size={[12, 12, 3]}
          >
            <Text type="h3">{title}</Text>
          </Column>
          {richText && renderRichText()}
          {children}
        </Row>
      </Container>
    </div>
  )
}

const LivingHomeModelDetail = ({ data, location }) => {
  useEffect(() => {
    document.head.innerHTML += '<meta name="robots" content="noindex">'
  }, []);
  const [modalOptions, setModalOptions] = React.useState({
    option: null,
    shown: false,
  })
  const isClient = useIsClient()
  let {
    allContentfulProject: modelProjectList,
    contentfulModelMaster: model,
  } = data

  model.model__variation_ =
    model &&
    model.model__variation_ &&
    model.model__variation_.map(variation => {
      return {
        ...variation,
        ...(variation.configUnitSizes && {
          config: variation.configUnitSizes.reduce(
            (acum, curr) => [...acum, curr.category],
            []
          ),
        }),
      }
    })
  let specificationsOverviewTableContent = []
  const hasVariableBeds = hasVariableValue(
    getValueList(model.model__variation_, "beds")
  )
  const hasVariableBathrooms = hasVariableValue(
    getValueList(model.model__variation_, "baths")
  )
  const hasVariableSize = hasVariableValue(
    getValueList(model.model__variation_, "squareFootage")
  )
  const hasVariableRanges = hasVariableValue(
    getValueList(model.model__variation_, "costRanges")
  )
  specificationsOverviewTableContent = [
    {
      bodyText:
        hasVariableBeds || hasVariableBathrooms
          ? `Variable`
          : `${getValueRange(
              model.model__variation_,
              "beds",
              true
            )} Bed | ${getValueRange(
              model.model__variation_,
              "baths",
              true
            )} Bath`,
      headingText: "Layout",
    },
  ]

  specificationsOverviewTableContent = [
    ...specificationsOverviewTableContent,
    {
      bodyText: hasVariableSize
        ? `Variable`
        : `${getValueRange(
            model.model__variation_,
            "squareFootage",
            true
          )} sq. ft.`,
      headingText: "Size",
    },
  ]

  let costQuote = model.slug === "brooks+scarpa-nest-livinghome-toolkit" ? costQuoteNest : costQuoteOthers

  let specificationsDetailsTableContent = [
    {
      bodyText: hasVariableBeds
        ? "Variable"
        : getValueRange(model.model__variation_, "beds", true),
      headingText: "Bedrooms",
    },
    {
      bodyText: hasVariableBathrooms
        ? "Variable"
        : getValueRange(model.model__variation_, "baths", true),
      headingText: "Bathrooms",
    },
    {
      bodyText: hasVariableSize
        ? "Variable"
        : `${getValueRange(
            model.model__variation_,
            "squareFootage",
            true
          )} sq. ft.`,
      headingText: "Floor Area",
    },
    {
      bodyText: hasVariableRanges
        ? "Variable"
        : model.model__variation_.length ? JSON.stringify(model.model__variation_[0].costRanges):'{}',
      // headingText: "Cost Ranges",
      richTextHeading: "Cost Ranges",
    },
    {},
    {}
    // {
    //   bodyText: [
    //     `Fabrication: $${model.fabricationCost}`,
    //     `Site Work: $${model.siteWorkCost}`,
    //     `Transport and Install: $${model.transportAndInstallCost}`,
    //     `Design, Engineering and Permitting: $${model.designEngineeringAndPermittingCost}`,
    //     `Total: $${parseInt(model.fabricationCost) +
    //       parseInt(model.siteWorkCost) +
    //       parseInt(model.transportAndInstallCost) +
    //       parseInt(model.designEngineeringAndPermittingCost)}`,
    //   ],

    //   // bodyText: `${model.modulePrice}`,
    //   headingText: "Cost Estimate",
    //   // pricingIcon: true,
    //   // subHeadingText:  model.title.match(/Series/ig) ?"Starting From:" : "",
    //   subHeadingText:  model.title.match(/Series/ig) ?"" : "",
    //   page: model.slug === "brooks+scarpa-nest-livinghome-toolkit"? "nest livinghome" :"",
    //   parseType:"model"
    // },
    // {
    //   bodyText: model.completeEstimate,
    //   headingText: "Hard Cost Estimate",
    //   pricingIcon: true,
    // },
    // {
    //   bodyText: JSON.stringify(costQuote.description),
    //   headingText: "",
    //   pricingIcon: true,
    // },
  ]

  // const showOptionsModal = option => {
  //   setModalOptions({ ...modalOptions, option, shown: true })
  // }
  const formatOptionTitle = title => (
    <div
      sx={{
        my: 2,
        mt: 3,
        mx: 4,
      }}
    >
      {title.split("(")[0]}
    </div>
  )

  const renderModal = () => {
    let singleLengthCategories = true
    let maxLengthOfCategories = 0
    let categories = {}
    if (modalOptions.option)
      modalOptions.option.options.forEach(option => {
        if (!categories[option.priceLevel]) {
          categories[option.priceLevel] = [option]
        } else
          categories[option.priceLevel] = [
            ...categories[option.priceLevel],
            option,
          ]
      })

    for (let keys in categories) {
      if (categories[keys].length) {
        if (categories[keys].length > 1) singleLengthCategories = false
        maxLengthOfCategories = Math.max(
          maxLengthOfCategories,
          categories[keys].length
        )
      }
    }
    return (
      <Modal
        title={formatOptionTitle(modalOptions.option.title)}
        onClose={() => setModalOptions({ ...modalOptions, shown: false })}
        customStyle={{
          // maxWidth: [null, null, null, 600],
          maxWidth:
            maxLengthOfCategories === 1
              ? [null, null, null, 200]
              : maxLengthOfCategories === 3
              ? [null, null, null, 450]
              : [null, null, null, 600],
          pb: 3,
        }}
      >
        {Object.keys(categories).map(categoryName => (
          <div
            sx={{
              ":first-of-type .title": { mt: 0 },
              px: ["20px", "20px", "20px", 0],
              ml: 4,
              mr: 1,
              // ":last-child": { mb: 0 },
            }}
          >
            <Text
              customStyle={{ fontWeight: "bold", mb: 3, mt: 3, fontSize: 14 }}
              className="title"
            >
              {categoryName} Options{categoryName === "Base" && " (Included)"}
            </Text>
            <Row customStyle={{ m: 0 }}>
              {categories[categoryName].map(option => (
                <Column
                  // size={!singleLengthCategories?[6, 3, 3, 3]:[6,6,6,6]}
                  size={
                    maxLengthOfCategories === 1
                      ? [11]
                      : maxLengthOfCategories === 3
                      ? [6, 4, 4, 4]
                      : [6, 3, 3, 3]
                  }
                  // size = {[6,3,3,3]}
                  customStyle={{ mb: 2, pr: "24px", pl: 0 }}
                >
                  <img
                    src={option.texture.fixed.src}
                    alt={option.color}
                    sx={{ width: "100%" }}
                  ></img>
                  <Text sx={{ fontWeight: "bold", fontSize: 14 }}>
                    {option.color}
                  </Text>
                </Column>
              ))}
            </Row>
          </div>
        ))}
      </Modal>
    )
  }

  const [showGatewayModal, setShowGatewayModal] = React.useState(false)
  const [showImageModal, setShowImageModal] = React.useState(false)
  const [imageValue, setImageValue] = React.useState("")
  const [isZoomIn, setZoomIn] = React.useState(false)
  const setImageModal = data => {
    setShowImageModal(true)
    setZoomIn(false)
    setImageValue(data)
  }
  const addFields = {
    tags: "PlanDownload",
  }
  paramsCheck()

  //  "{\"nodeType\":\"paragraph\",\"content\":[{\"nodeType\":\"text\",\"value\":\"See \",\"marks\":[{\"type\":\"italic\"}],\"data\":{}},{\"nodeType\":\"hyperlink\",\"content\":[{\"nodeType\":\"text\",\"value\":\"pricing page\",\"marks\":[{\"type\":\"italic\"}],\"data\":{}}],\"data\":{\"uri\":\"https://www.plantprefab.com/\"}},{\"nodeType\":\"text\",\"value\":\" for estimate assumptions and exclusions\",\"marks\":[{\"type\":\"italic\"}],\"data\":{}}],\"data\":{}},{\"nodeType\":\"paragraph\",\"content\":[{\"nodeType\":\"text\",\"value\":\"\",\"marks\":[{\"type\":\"italic\"}],\"data\":{}}],\"data\":{}}"
  const numberWithCommas = x => {
    if (x && !isNaN(x))
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    else return x
  }

  return (
    <React.Fragment>
      {!showImageModal && (
        <React.Fragment>
          <SEO
            title={model.title}
            description={get(model, "metaDescription")}
            imageUrl={get(model, "model__variation_[0].images[0].file.url")}
          />
          {modalOptions.shown && renderModal()}
          <ModelStickyHeader
            title={model.title}
            configuratorUrl={model.configuratorUrl}
          />
          {model.model__variation_ && (
            <React.Fragment>
              <Container>
                <Row>
                  <Column>
                    <SpecificationsOverview
                      pathname={location.pathname}
                      tableContent={specificationsOverviewTableContent}
                      title={model.title}
                      displayTitle={model.displayTitle}
                    />
                  </Column>
                </Row>
              </Container>
              {renderCarousel({ standardHome: model.model__variation_ })}
            </React.Fragment>
          )}

          <SpecificationsDetails
            id="model-overview"
            sx={{ py: theme => theme.spacing.vertical.lg }}
            description={model.description && model.description.json}
            richText={model.factoid && model.factoid.json}
            tableContent={specificationsDetailsTableContent}
            locationUrl={location.pathname}
          />
          {model.model__variation_ && model.model__variation_.length && (
            <div
              id="technical-details"
              sx={{
                backgroundColor: theme => theme.colors.backgrounds.primary,
                pt: theme => theme.spacing.vertical.lg,
              }}
            >
              <Container>
                <Row>
                  <Column>
                    <Text type="h2">
                      Technical
                      <br />
                      Details
                    </Text>
                  </Column>
                </Row>
              </Container>
              <Tabs sx={{ mt: theme => theme.spacing.vertical.md }}>
                <TabList
                  sx={{
                    maxWidth: "88%",
                    mx: [0, 0, "auto"],
                    px: theme => [
                      theme.spacing.horizontal,
                      theme.spacing.horizontal,
                      0,
                    ],
                  }}
                >
                  {model.model__variation_.map(standardHome => (
                    <Tab key={standardHome.id} text={standardHome.name} />
                  ))}
                </TabList>
                <TabPanels>
                  {model.model__variation_.map(standardHome => (
                    <TabPanel key={standardHome.id}>
                      <div sx={{ backgroundColor: "white" }}>
                        {standardHome.plans && standardHome.plans.length && (
                          <Container>
                            <Row>
                              <Column
                                customStyle={{
                                  py: theme => [
                                    0,
                                    0,
                                    theme.spacing.vertical.lg,
                                  ],
                                }}
                              >
                                <Carousel type="quinary">
                                  {standardHome.plans.map(
                                    (contentData, index) => {
                                      return (
                                        <React.Fragment>
                                          <span
                                            onClick={e => {
                                              e.preventDefault()
                                              setImageModal(contentData)
                                            }}
                                          >
                                            {/* <a href={contentData.fluid.src.split('?')[0]}> */}
                                            <Img
                                              sx={{
                                                maxWidth: 1000,
                                                mx: "auto",
                                                cursor: "pointer",
                                              }}
                                              alt={contentData.title}
                                              fluid={contentData.fluid}
                                              key={contentData.id + index}
                                              className="pk-zoom-icon-popup"
                                            />
                                            {/* </a> */}
                                          </span>
                                        </React.Fragment>
                                      )
                                    }
                                  )}
                                </Carousel>
                              </Column>
                            </Row>
                          </Container>
                        )}
                      </div>
                      <TechnicalDetailsContentRow title="Specifications">
                        <Column size={[12, 12, null]}>
                          <Text
                            customStyle={
                              {
                                // fontWeight: "bold"
                              }
                            }
                          >
                            {`${findAndReplaceVariableValue(
                              standardHome.beds
                            )} Bedrooms`}
                          </Text>
                          <Text
                            customStyle={
                              {
                                //  fontWeight: "bold"
                              }
                            }
                          >
                            {`${findAndReplaceVariableValue(
                              standardHome.baths
                            )} Bathrooms`}
                          </Text>
                        </Column>
                        <Column size={[12, 12, null]}>
                          <Text
                            customStyle={
                              {
                                //  fontWeight: "bold"
                              }
                            }
                          >
                            {`${findAndReplaceVariableValue(
                              standardHome.squareFootage
                            )} Square Feet`}
                          </Text>
                          {standardHome.width && standardHome.length && (
                            <Text
                              customStyle={
                                {
                                  // fontWeight: "bold"
                                }
                              }
                            >
                              {standardHome.width} x {standardHome.length} Total
                              Area
                            </Text>
                          )}
                        </Column>
                        <Column
                          customStyle={{
                            mt: theme => [
                              theme.spacing.vertical.sm,
                              theme.spacing.vertical.sm,
                              0,
                            ],
                          }}
                          size={[12, 12, null]}
                        >
                          {showGatewayModal && (
                            <GatewayForm
                              onClose={() => setShowGatewayModal(false)}
                              detail={`Download Plan`}
                              plansDownloadModel={model.title}
                              addFieldsDefault={addFields}
                              // formfield={}
                              // goalName={standardHome.name ? `Plan Download - ${standardHome.name} - Thank You` : `Plan Download -- Thank You`}
                              goalName={
                                standardHome.name
                                  ? `Plan Download Form Submit`
                                  : `Plan Download Form Submit`
                              }
                              conversionDetail={`LrQGCLDnjNUBEJeLhv0C`}
                              sizeSQFT={findAndReplaceVariableValue(
                                standardHome.squareFootage
                              )}
                              onSubmit={() => {
                                let win = window.open(
                                  standardHome.plansDownload.file.url,
                                  "_blank"
                                )
                                win.focus()
                              }}
                              downloadPlan={() => {
                                let win = window.open(
                                  standardHome.plansDownload.file.url,
                                  "_blank"
                                )
                                win.focus()
                              }}
                            />
                          )}
                          {standardHome.plansDownload && (
                            <div>
                              <CTA
                                type="alt"
                                link="/"
                                // onClick={e => {
                                //   e.preventDefault()
                                //   setShowGatewayModal(true)
                                // }}
                                onClick={() => {
                                  let win = window.open(
                                    standardHome.plansDownload.file.url,
                                    "_blank"
                                  )
                                  win.focus()
                                }}
                              >
                                Download Plans
                              </CTA>
                            </div>
                          )}
                        </Column>
                      </TechnicalDetailsContentRow>
                      <TechnicalDetailsContentRow
                        title="Features"
                        richText={standardHome.features}
                        type="secondary"
                      >
                        {standardHome.featuresDownload && (
                          <Column
                            customStyle={{
                              mt: theme => [
                                theme.spacing.vertical.sm,
                                theme.spacing.vertical.sm,
                                0,
                              ],
                            }}
                            size={[12, 12, null]}
                          >
                            <div>
                              <CTA
                                type="secondary"
                                linkType="external"
                                link={standardHome.featuresDownload.file.url}
                              >
                                Learn More
                              </CTA>
                            </div>
                          </Column>
                        )}
                      </TechnicalDetailsContentRow>
                      {standardHome.customizationOptions ? (
                        <TechnicalDetailsContentRow
                          title="Customization Options"
                          richText={standardHome.customizationOptions}
                          type={
                            standardHome.customizationOptions
                              ? "primary"
                              : "secondary"
                          }
                        >
                          {/* {standardHome.lookbookUrl && */}
                          {/* {standardHome.lookbookMediaUrl && (
                            <Column
                              customStyle={{
                                mt: theme => [
                                  theme.spacing.vertical.sm,
                                  theme.spacing.vertical.sm,
                                  0,
                                ],
                              }}
                              size={[12, 12, null]}
                            >
                              <div>
                                <CTA
                                  type="secondary"
                                  linkType="external"
                                  link={standardHome.lookbookMediaUrl.file.url}
                                >
                                  See Lookbook
                                </CTA>
                              </div>
                            </Column>
                          )} */}
                        </TechnicalDetailsContentRow>
                      ) : null}

                      {/* {standardHome.configUnitSizes && (
                      <TechnicalDetailsContentRow
                        title="Customization Options"
                        type="primary"
                      >
                        {chunk(
                          standardHome.config,
                          Math.round(standardHome.config.length / 2)
                        ).map((column, index) => (
                          <Column key={index} size={[12, 12, 3]}>
                            {column.map((option, index) => (
                              <Text
                                key={index}
                                customStyle={{
                                  fontWeight: "bold",
                                  color: "primary",
                                  cursor: "pointer",
                                  mb: 1,
                                }}
                                onClick={() => showOptionsModal(option)}
                              >
                                {formatOptionTitle(option.title)}
                              </Text>
                            ))}
                          </Column>
                        ))}
                      </TechnicalDetailsContentRow>
                    )} */}
                      {/* <TechnicalDetailsContentRow
                        title="Cost Estimate"
                        type={
                          standardHome.customizationOptions
                            ? "secondary"
                            : "primary"
                        }
                      >
                        <Column size={[12, 12, null]}>
                          {standardHome.fabricationCost && (
                            <Text
                              customStyle={{
                                // fontWeight: "bold"
                                mb: "10px",
                              }}
                            >
                             
                              <span sx={{ fontWeight: 500 }}>
                                Fabrication:{" "}
                              </span>{"From "}
                              ${numberWithCommas(standardHome.fabricationCost)}
                            </Text>
                          )}
                          {standardHome.siteWorkCost && (
                            <Text
                              customStyle={{
                                mb: "10px",
                              }}
                            >
                              <span sx={{ fontWeight: 500 }}>Site Work: </span>{"From "}
                              ${numberWithCommas(standardHome.siteWorkCost)}
                            </Text>
                          )}
                          {standardHome.transportAndInstallCost && (
                            <Text
                              customStyle={{
                                mb: "10px",
                              }}
                            >
                              <span sx={{ fontWeight: 500 }}>
                                Transport and Install:{" "}
                              </span>{"From "}
                              $
                              {numberWithCommas(
                                standardHome.transportAndInstallCost
                              )}
                              
                            </Text>
                          )}
                          {standardHome.designEngineeringAndPermittingCost && (
                            <Text
                              customStyle={{
                                WordWrap: "break-word",
                                mb: "10px",
                              }}
                            >
                              <span sx={{ fontWeight: 500 }}>
                                Design, Engineering
                              </span>{" "}
                              <br />
                              <span sx={{ fontWeight: 500 }}>
                                and Permitting:{" "}
                              </span>{"From "}
                              $
                              {numberWithCommas(
                                standardHome.designEngineeringAndPermittingCost
                              )}
                              
                            </Text>
                          )}

                          <Text
                            customStyle={
                              {
                                // fontWeight: "bold"
                              }
                            }
                          >
                            
                          </Text>
                          {model.slug ==="brooks+scarpa-nest-livinghome-toolkit" ? (
                            <RichText data={costQuote.description} />
                          ) : null}
                        </Column>
                        <Column
                          customStyle={{
                            mt: theme => [
                              theme.spacing.vertical.sm,
                              theme.spacing.vertical.sm,
                              0,
                            ],
                          }}
                          size={[12, 12, null]}
                        >
                          <Text
                            customStyle={
                              {
                                //  fontWeight: "bold"
                                //  mb:"10px"
                              }
                            }
                          >
                            
                            {(standardHome.fabricationCost ||
                              standardHome.siteWorkCost ||
                              standardHome.transportAndInstallCost ||
                              standardHome.designEngineeringAndPermittingCost) && (
                              <Text
                                customStyle={{
                                  mb: "10px",
                                }}
                              >
                                <span sx={{ fontWeight: 500 }}>Total: </span>{"From "}$
                                {numberWithCommas(
                                  parseInt(standardHome.fabricationCost) +
                                    parseInt(standardHome.siteWorkCost) +
                                    parseInt(
                                      standardHome.transportAndInstallCost
                                    ) +
                                    parseInt(
                                      standardHome.designEngineeringAndPermittingCost
                                    )
                                )}
                                
                              </Text>
                            )}
                            <div
                              sx={{
                                maxWidth: "75%",
                              }}
                            >
                              {model.slug === "brooks+scarpa-nest-livinghome-toolkit" ? null : (
                                <RichText data={costQuote.description} />
                              )}
                            </div>
                          </Text>
                          
                        </Column>
                        <Column
                          customStyle={{
                            mt: theme => [
                              theme.spacing.vertical.sm,
                              theme.spacing.vertical.sm,
                              0,
                            ],
                          }}
                          size={[12, 12, null]}
                        >
                          <div>
                            <CTA
                              type="secondary"
                              link={
                                isClient
                                  ? `/contact?ref=${window.location.href}`
                                  : "/contact"
                              }
                              // customStyle={{minWidth:"80px !important"}}
                            >
                              Contact Us
                            </CTA>
                          </div>
                        </Column>
                      </TechnicalDetailsContentRow> */}

                     
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </div>
          )}

          {model.designPartner && (
            <div
              sx={{
                bg: theme => theme.colors.backgrounds.primary,
                py: theme => theme.spacing.vertical.lg,
              }}
            >
              <DesignPartnerDetails
                currentModelMasterSlug={model.slug}
                name={model.designPartner.name}
                photo={model.designPartner.photo}
                relatedModelMasterList={model.designPartner.model__master_}
                shortBio={
                  model.designPartner.shortBio &&
                  model.designPartner.shortBio.json
                }
                slug={model.designPartner.slug}
                isReadBio={true}
              />
            </div>
          )}

          {modelProjectList.edges.length > 0 && (
            <FinishedProjectsSection
              modelProjectList={modelProjectList.edges}
            />
          )}

          {data.contentfulBladePattern &&
            data.contentfulBladePattern.contentCards &&
            data.contentfulBladePattern.contentCards.length && (
              <div id={data.contentfulBladePattern.anchorLink}>
                <TabbedCards
                  titleStyle={{
                    textAlign: ["left", "center"],
                    mb: theme => [3, theme.spacing.vertical.lg],
                    fontSize: [36, 40],
                    maxWidth: [300, "100%"],
                  }}
                  data={data.contentfulBladePattern}
                />
              </div>
            )}
        </React.Fragment>
      )}
      {showImageModal && (
        <ImageModal
          onClose={() => setShowImageModal(false)}
          onZoom={() => setZoomIn(!isZoomIn)}
          data={imageValue}
          isZoomIn={isZoomIn}
        />
      )}
    </React.Fragment>
  )
}

export default LivingHomeModelDetail

export const query = graphql`
  query($slug: String!) {
    contentfulModelMaster(slug: { eq: $slug }) {
      metaDescription
      displayTitle
      description {
        json
      }
      designPartner {
        shortBio {
          json
        }
        photo {
          fluid(maxWidth: 150) {
            ...GatsbyContentfulFluid_withWebp
          }
          id
          title
        }
        model__master_ {
          id
          model__variation_ {
            baths
            beds
            id
            images {
              fluid(maxWidth: 800) {
                ...GatsbyContentfulFluid_withWebp
              }
              id
              title
            }

            name
            squareFootage
            costRanges {  # Added the costRanges field here
              json
            }
          }
          slug
          title
        }
        name
        slug
      }
      efficiency {
        json
      }
      factoid {
        json
      }
      id
      fabricationCost
      siteWorkCost
      transportAndInstallCost
      designEngineeringAndPermittingCost
      model__variation_ {
        id
        baths
        beds
        fabricationCost
        siteWorkCost
        transportAndInstallCost
        designEngineeringAndPermittingCost
        features {
          json
        }
        customizationOptions {
          json
        }
        costRanges {
          json
        }
        featuresDownload {
          file {
            url
          }
        }
        lookbookMediaUrl {
          file {
            url
          }
        }
        images {
          file {
            url
          }
          id
          title
        }
        length
        name
        plans {
          fluid(maxWidth: 1000) {
            ...GatsbyContentfulFluid_withWebp
          }
          id
          title
        }
        squareFootage
        width
        plansDownload {
          file {
            url
          }
        }
        configUnitSizes {
          category {
            title
            options {
              color
              priceLevel
              texture {
                fixed(height: 200, width: 200) {
                  ...GatsbyContentfulFixed
                  width
                }
              }
            }
          }
        }
      }
      technicalDetailsFactoid {
        json
      }
      slug
      title
      configuratorUrl
      homeConfigOptionSet {
        options {
          title
          options {
            optionTitle
            texture {
              fixed(height: 200, width: 200) {
                ...GatsbyContentfulFixed
                width
              }
            }
          }
        }
      }
    }
    allContentfulProject(
      sort: { fields: createdAt, order: DESC }
      filter: { model: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          id
          projectTitle {
            projectTitle
          }
          projectLocation {
            projectLocation
          }
          projectImages {
            file {
              url
            }
            id
            title
          }
          slug
        }
      }
    }
    contentfulBladePattern(slug: { eq: "anatomy-of-a-livinghome" }) {
      contentCards {
        ... on ContentfulBasicCard {
          ctaDestination {
            ctaDestination
          }
          ctaTitle
          description {
            json
          }
          desktopImage {
            fluid(maxHeight: 350, maxWidth: 1000) {
              ...GatsbyContentfulFluid_withWebp
            }
            id
            title
          }
          id
          title
        }
      }
      title
      anchorLink
    }
  }
`
